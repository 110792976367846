import {Button, Col, Drawer, Form, Input, InputNumber, Row, Select} from "antd";

function FormDrawer({ open, onClose, onSubmit, onChange, items, title, loading = false, width = 500}) {
    return (
        <Drawer
            title={title}
            open={open}
            onClose={onClose}
            width={width}
        >
            <Row
                gutter={16}
            >
                {items.map((value, index) => {
                    return (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={12}
                        >
                            <Form.Item
                                initialValue={value.initValue}
                                name={value.name}
                                label={value.label}
                                rules={[
                                    {
                                        required: value.required,
                                    }
                                ]}
                                layout={'vertical'}
                            >
                                {(() => {
                                    if (value.type === 'text') {
                                        return (
                                            <Input
                                                placeholder={value.placeholder}
                                                disabled={value.disabled}
                                            />
                                        )
                                    }
                                    else if (value.type === 'password') {
                                        return (
                                            <Input.Password
                                                placeholder={value.placeholder}
                                            />
                                        )
                                    }
                                    else if (value.type === 'select') {
                                        return (
                                            <Select
                                                style={{
                                                    width: '100%'
                                                }}
                                                options={value.options}
                                                placeholder={value.placeholder}
                                                showSearch={true}
                                                optionFilterProp={'label'}
                                                onChange={onChange}
                                            />
                                        )
                                    }
                                    else if (value.type === 'number') {
                                        return (
                                            <InputNumber
                                                style={{
                                                    width: '100%'
                                                }}
                                                placeholder={value.placeholder}
                                                min={0}
                                                disabled={value.disabled}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        )
                                    }
                                })()}
                            </Form.Item>
                        </Col>
                    )
                })}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Button onClick={onSubmit} loading={loading} type={'primary'} htmlType={'submit'}>Xác nhận</Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default FormDrawer;