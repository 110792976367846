import axios from "../axios";

const getBankTransfers = async function (accessToken) {
    try {
        const response = await axios.get('admin/withdraw-transaction/getBankTransfers',{
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getBankTransfers;