import Container from "../../components/Container/Container";
import {Form, Table, Tag} from "antd";
import {useContext, useEffect, useState} from "react";
import LoadingContext from "../../contexts/LoadingContext";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {formatDateTime, numberFormat} from "../../helpers/helpers";
import getStatistics from "../../api/admin/statistic/getStatistics";
import Search from "../../components/Search/Search";
import searchPartner from "../../api/partner/searchPartner";
import getStatisticDetail from "../../api/admin/statistic/getStatisticDetail";

function Statistic({ type = 'in'}) {
    const accessToken  = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [options,setOptions] = useState([]);
    const [query,setQuery] = useState('');
    const [expandDataSource, setExpandDataSource] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'userId',
            type: 'search',
            placeholder: 'Nhập tên đối tác',
            options: options.map((item) => {
                return {
                    value: item.userId,
                    text: item.name
                }
            }),
            onSearch: (value) => {
                setQuery(value)
            }
        },
        {
            name: 'date',
            type: 'day',
        }
    ];

    const columns = [
        {
            title: 'Đối tác',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            align: 'center',
            render: (value) => {
                return <Tag color={'blue'}>{value}</Tag>
            }
        },
        {
            title: 'Tổng tiền giao dịch',
            dataIndex: 'totalAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng phí',
            dataIndex: 'totalFee',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng số dư',
            dataIndex: 'totalBalance',
            align: 'center',
            render: (_, record) => {
                if (record.type === 'in') {
                    return <Tag color={'green'}>{'+' + numberFormat(record.totalAmount - record.totalFee)}</Tag>
                }
                return <Tag color={'red'}>{'-' + numberFormat(record.totalAmount + record.totalFee)}</Tag>;
            }
        },
    ];

    const expandColumns = [
        {
            title: 'Ngân hàng/Ví',
            dataIndex: 'bankName',
            align: 'center',
            render: (value, record) => {
                if (record.bankType === 'bank') {
                    return <Tag color={'blue'}>{value}</Tag>
                } else if (record.bankType === 'wallet') {
                    return <Tag color={'magenta'}>{value}</Tag>
                } else if (record.bankType === 'business') {
                    return <Tag color={'volcano'}>{value}</Tag>
                }
            }
        },
        {
            title: 'Tổng tiền giao dịch',
            dataIndex: 'totalAmount',
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng phí',
            dataIndex: 'totalFee',
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng số dư',
            dataIndex: 'totalBalance',
            align: 'center',
            render: (_, record) => {
                if (record.type === 'in') {
                    return <Tag color={'green'}>{'+' + numberFormat(record.totalAmount - record.totalFee)}</Tag>
                }
                return <Tag color={'red'}>{'-' + numberFormat(record.totalAmount + record.totalFee)}</Tag>;
            }
        }
    ]

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getStatistics(accessToken, type, tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    const handleExpandedData = async (expanded, record) => {
        if (!expanded) {
            setExpandDataSource([]);
            return;
        }
        setTableLoading(true);
        const response = await getStatisticDetail(accessToken, record.username, type, tableParams.search);
        if (response.success) {
            setExpandDataSource(response.data);
        }
        setTableLoading(false);
    }

    const expandedRowRender = () => (
        <Table
            rowKey={'bankName'}
            columns={expandColumns}
            dataSource={expandDataSource}
            pagination={false}
        />
    );

    useEffect(() => {
        setLoading(true);
        const fetchPartners = async () => {
            const response = await searchPartner(accessToken,query);
            if (response.success) {
                setOptions(response.data)
            }
        }
        fetchPartners().finally(setLoading(false));
    }, [query])

    useEffect(() => {
        setExpandDataSource([]);
        fetchData().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
        type
    ]);

    return (
        <Container>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'username'}
                columns={columns}
                dataSource={dataSource}
                loading={tableLoading}
                bordered={true}
                onChange={handleTableChange}
                pagination={false}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                onExpand={handleExpandedData}
                summary={(data) => {
                    const totalAmount = data.reduce((total, user) => {
                        return total + parseInt(user.totalAmount, 10);
                    }, 0);
                    const totalFee = data.reduce((total, user) => {
                        return total + parseInt(user.totalFee, 10);
                    }, 0);
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(totalFee)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default Statistic;