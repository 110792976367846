import { createSlice } from '@reduxjs/toolkit';

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        login: {
            isWaiting: false,
            error: false,
            errorMessage: null,
            userData: {},
            accessToken: null
        }
    },
    reducers: {
        loginStart: (state) => {
            state.login.isWaiting = true;
        },
        loginError: (state,action) => {
            state.login.isWaiting       = false;
            state.login.error           = true;
            state.login.errorMessage    = action.payload.message;
        },
        loginSuccess: (state,action) => {
            const { payload }               = action;
            const { accessToken, userData, expiredAt } = payload;
            state.login.error       = false;
            state.login.errorMessage= null;
            state.login.isWaiting   = false;
            state.login.accessToken = accessToken;
            state.login.userData    = userData;
            localStorage.setItem('accessToken',accessToken);
            localStorage.setItem('userData',JSON.stringify(userData));
            localStorage.setItem('expiredAt', expiredAt)
        }
    }
});

export const { loginStart, loginError,loginSuccess } = AuthSlice.actions;
export default AuthSlice.reducer