import axios from "../axios";

const getWithdrawals = async function (accessToken,params = {}) {
    try {
        const response = await axios.post('withdraw/getWithdrawals', params,{
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getWithdrawals;