import SiderBarContext from "../contexts/SiderBarContext";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {appRoutes} from "../routes/web";

function SiderBarProvider({children}) {
    const location = useLocation();
    const [keyActive, setKeyActive] = useState(() => {
        const activeKey = appRoutes.find((value) => value.path && value.path.includes(location.pathname));
        return activeKey ? activeKey.key : null;
    });

    return (
        <SiderBarContext.Provider value={[keyActive,setKeyActive]}>{children}</SiderBarContext.Provider>
    )
}

export default SiderBarProvider;