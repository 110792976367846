import {Flex, Tag} from "antd";
import {numberFormat} from "../../helpers/helpers";

function CountLimit({count,total}) {
    const remaining = total - count;
    return (
        <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
            <Tag>{count}</Tag>
            <Tag>{remaining}</Tag>
        </Flex>
    )
}

export default CountLimit;