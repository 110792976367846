import axios from "../axios";

const getStatistics = async function (accessToken,params = {}) {
    try {
        const response = await axios.post('/statistic/getStatistics', params ,{
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getStatistics;