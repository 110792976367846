import axios from "../axios";

const getBalance = async function (accessToken,name,email) {
    try {
        const response = await axios.post('/user/updateProfile', {
            name,
            email
        }, {
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getBalance;