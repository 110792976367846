import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import PayIn from "../pages/PayIn";
import {DashboardOutlined, SolutionOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowTrendUp, faBank, faBook, faChartLine, faChartPie, faCogs,
    faCoins,
    faCommentDollar, faFileImport, faHandHoldingDollar, faHistory,
    faMoneyBillTransfer, faMoneyBillTrendUp, faPiggyBank,
    faSackDollar, faUser, faWallet
} from "@fortawesome/free-solid-svg-icons";
import ItemLabel from "../components/TopMenu/ItemLabel/ItemLabel";
import Verify from "../pages/auth/Verify";
import Configure from "../pages/Configure";
import Bank from "../pages/Bank";
import BankAccount from "../pages/BankAccount";
import Transaction from "../pages/Transaction";
import Hook from "../pages/Hook";
import AdminHook from "../pages/admin/Hook"
import Withdraw from "../pages/Withdraw";
import PayOut from "../pages/PayOut";
import Partner from "../pages/partner/Partner";
import AdminTransaction from "../pages/admin/Transaction";
import AdminPayIn from "../pages/admin/PayIn";
import AdminPayOut from "../pages/admin/PayOut";
import BankTransaction from "../pages/BankTransaction";
import AdminWithdraw from "../pages/admin/Withdraw";
import AdminStatistic from "../pages/admin/Statistic";
import AdminStatisticDetail from "../pages/admin/StatisticDetail";
import Statistic from "../pages/Statistic";
import WithdrawTransaction from "../pages/WithdrawTransaction";
import WithdrawAuto from "../pages/admin/WithdrawAuto";
import Administration from "../pages/Administration";
import WalletAccount from "../pages/WalletAccount";

const authRoutes = [
    {
        path: '/auth/login',
        element: <Login/>
    },
    {
        path: '/auth/verify',
        element: <Verify/>
    }
];

const appRoutes = [
    {
        type: 'item',
        icon: <DashboardOutlined/>,
        path: '/',
        element: <Dashboard/>,
        label: 'Trang chủ',
        key: 'dashboard',
        roles: ['admin','partner']
    },
    {
        type: 'group',
        label: <ItemLabel>TRA CỨU</ItemLabel>,
        roles: ['admin','supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faCommentDollar}/>,
        path: '/admin/pay-in',
        element: <AdminPayIn/>,
        label: 'Pay in',
        key: 'admin_pay_in',
        roles: ['admin','supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faCoins}/>,
        path: '/admin/pay-out',
        element: <AdminPayOut/>,
        label: 'Pay out',
        key: 'admin_pay_out',
        roles: ['admin','supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faMoneyBillTransfer}/>,
        path: '/admin/withdraw',
        element: <AdminWithdraw/>,
        label: 'Rút số dư',
        key: 'admin_withdraw',
        roles: ['admin', 'supporter']
    },
    {
        type: 'group',
        label: <ItemLabel>CHỨC NĂNG</ItemLabel>,
        roles: ['partner']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faCommentDollar}/>,
        path: '/pay-in',
        element: <PayIn/>,
        label: 'Pay in',
        key: 'pay_in',
        roles: ['partner']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faCoins}/>,
        path: '/pay-out',
        element: <PayOut/>,
        label: 'Pay out',
        key: 'pay_out',
        roles: ['partner']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faSackDollar}/>,
        path: '/transactions/withdraw',
        element: <Withdraw/>,
        label: 'Rút số dư',
        key: 'withdraw',
        roles: ['partner']
    },
    {
        type: 'group',
        label: <ItemLabel>CẤU HÌNH</ItemLabel>,
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faCogs}/>,
        path: '/configure',
        element: <Configure/>,
        label: 'Cấu hình',
        key: 'configure',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faBank}/>,
        path: '/configure/bank',
        element: <Bank/>,
        label: 'Cấu hình ngân hàng',
        key: 'bank',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faHandHoldingDollar}/>,
        path: '/withdraw-auto/account',
        element: <WithdrawAuto/>,
        label: 'Rút tiền tự động',
        key: 'withdraw_auto_account',
        roles: ['admin']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faWallet}/>,
        path: '/configure/bank-account/wallet',
        element: <WalletAccount/>,
        label: 'Danh sách ví điện tử',
        key: 'bank_account_wallet',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faPiggyBank}/>,
        path: '/configure/bank-account',
        element: <BankAccount/>,
        label: 'Danh sách ngân hàng',
        key: 'bank_account',
        roles: ['admin', 'supporter']
    },
    {
        type: 'group',
        label: <ItemLabel>THỐNG KÊ</ItemLabel>,
        roles: ['admin','partner', 'supporter']
    },
    {
        type: 'children',
        icon: <FontAwesomeIcon icon={faChartPie}/>,
        label: 'Tổng quan',
        key: 'admin_statistic',
        roles: ['admin', 'supporter'],
        children: [
            {
                path: '/admin/statistic/in',
                element: <AdminStatistic type={'in'}/>,
                label: 'Pay in',
                key: 'admin_statistic_in',
            },
            {
                path: '/admin/statistic/out',
                element: <AdminStatistic type={'out'}/>,
                label: 'Pay out',
                key: 'admin_statistic_out',
            }
        ]
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faChartPie}/>,
        path: '/statistic/',
        element: <Statistic/>,
        label: 'Tổng quan',
        key: 'statistic',
        roles: ['partner']
    },
    {
        type: 'group',
        label: <ItemLabel>LỊCH SỬ</ItemLabel>,
        roles: ['admin','partner', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faArrowTrendUp}/>,
        path: '/admin/transactions/',
        element: <AdminTransaction/>,
        label: 'Biến động số dư',
        key: 'admin_transaction',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faHistory}/>,
        path: '/admin/bank-transactions/',
        element: <BankTransaction/>,
        label: 'Giao dịch ngân hàng',
        key: 'admin_bank_transaction',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faMoneyBillTrendUp}/>,
        path: '/admin/withdraw-transactions/',
        element: <WithdrawTransaction/>,
        label: 'Rút tiền ngân hàng',
        key: 'admin_withdraw_transaction',
        roles: ['admin' , 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faBook}/>,
        path: '/admin/log-webhook/hook-api',
        element: <AdminHook/>,
        label: 'Lịch sử hook',
        key: 'admin/log_hook',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faArrowTrendUp}/>,
        path: '/transactions/',
        element: <Transaction/>,
        label: 'Biến động số dư',
        key: 'transaction',
        roles: ['partner']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faBook}/>,
        path: '/log-webhook/hook-api',
        element: <Hook/>,
        label: 'Lịch sử hook',
        key: 'log_hook',
        roles: ['partner']
    },
    {
        type: 'group',
        label: <ItemLabel>QUẢN LÝ</ItemLabel>,
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faUser}/>,
        path: '/admin/partner',
        element: <Partner/>,
        label: 'Đối tác',
        key: 'partner',
        roles: ['admin', 'supporter']
    },
    {
        type: 'item',
        icon: <SolutionOutlined/>,
        path: '/admin/administration',
        element: <Administration/>,
        label: 'Quản trị',
        key: 'administration',
        roles: ['admin']
    },
    {
        type: 'group',
        label: <ItemLabel>TÀI LIỆU</ItemLabel>,
        roles: ['admin', 'supporter', 'partner']
    },
    {
        type: 'item',
        icon: <FontAwesomeIcon icon={faFileImport}/>,
        path: process.env.REACT_APP_DOCUMENT_URL,
        label: 'Document',
        key: 'document',
        roles: ['admin', 'supporter', 'partner']
    },
];


const redirectAuth = '/auth/login';

const redirectApp = '/';

export {authRoutes,appRoutes,redirectAuth,redirectApp};