import Container from "../../components/Container/Container";
import {Avatar, Button, Card, Col, Flex, Form, Input, message, Row, Upload} from "antd";
import {AntDesignOutlined} from "@ant-design/icons";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import Info from "../../components/Info/Info";
import {useContext, useState} from "react";
import LoadingContext from "../../contexts/LoadingContext";
import updateProfile from "../../api/user/updateProfile";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {useNavigate} from "react-router-dom";
import {redirectAuth} from "../../routes/web";
import {getBase64} from "../../helpers/helpers";

function Profile() {
    const userData = useAuthUser();
    const accessToken = useAuthHeader();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const {setLoading} = useContext(LoadingContext);
    const [name,setName]    = useState(userData.name);
    const [email,setEmail]  = useState(userData.email);
    const [messageApi, contextHolder] = message.useMessage();
    const [image,setImageUrl] = useState('');
    const handleUpdate = async () => {
        setLoading(true);
        const response = await updateProfile(accessToken,name,email);
        if (!response.success && response.errorCode === 401) {
            signOut();
            navigate(redirectAuth);
        }
        else if (!response.success){
            messageApi.open({
                type: 'error',
                content: response.message,
            });
        }
        else {
            messageApi.open({
                type: 'success',
                content: response.message,
            });
        }
        setLoading(false);
    }

    const handleUploadChange = (info) => {
        if (info.file.status) {
            getBase64(info.file.originFileObj, imageUrl => {
                setImageUrl(imageUrl);
            });
        }
    }

    return (
        <Container>
            {contextHolder}
            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Card title={'Thông tin tài khoản'}>
                        <Flex justify={'center'} align={'center'}>
                            <Upload
                                name={'avatar'}
                                showUploadList={false}
                                onChange={handleUploadChange}
                                beforeUpload={(file) => false}
                            >
                                <Avatar
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    size={{
                                        xs: 120,
                                        sm: 120,
                                        md: 120,
                                        lg: 140,
                                        xl: 140,
                                        xxl: 160,
                                    }}
                                    icon={<AntDesignOutlined />}
                                />
                            </Upload>
                        </Flex>
                        <Info title={'Tên tài khoản'}>{userData.username}</Info>
                        <Info title={'Email'}>{userData.email}</Info>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                    <Card title={'Cập nhật thông tin'}>
                        <Form
                            layout={'horizontal'}
                            labelCol={{
                                span: 4
                            }}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item label={'Biệt danh'} name={'name'} initialValue={name}
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        <Input placeholder={userData.name} onChange={(e) => setName(e.target.value)}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item label={'Email'} name={'email'} initialValue={email}
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        <Input placeholder={'Email'} onChange={(e) => setEmail(e.target.value)}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item label={'UserAgent'}
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        <Input.TextArea disabled value={userData.userAgent}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Flex justify={'end'}>
                                        <Button type={'primary'} onClick={handleUpdate}>Cập nhật</Button>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile;