import axios from "../axios";

const getInfoTelegram = async function (accessToken, userId) {
    try {
        const response = await axios.get('partner/getInfoTelegram' ,{
            headers: {
                'Authorization': accessToken
            },
            params: {
                userId
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getInfoTelegram;