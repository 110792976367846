import {Tag} from "antd";

function BankAccountType({type}) {
    let value;
    let color = 'blue';
    switch (type) {
        case 'in':
            value = 'IN';
            break;
        case 'out':
            color = 'red';
            value = 'OUT';
            break;
        case 'in_out':
            color = 'yellow';
            value = 'IN và OUT'
            break;
        case 'hook':
            color = 'volcano';
            value = 'Cho thuê';
            break
    }
    return (
        <>
            <Tag color={color}>{value}</Tag>
        </>
    )
}

export default BankAccountType;