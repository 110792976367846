import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from "../redux/AuthSlice";
import UserSlice from "../redux/UserSlice";
import DeviceSlice from "../redux/DeviceSlice";
import CollapsedSlice from "../redux/CollapsedSlice";
import SideBarSlice from "../redux/SideBarSlice";

export default configureStore({
    reducer: {
        auth: AuthSlice,
        user: UserSlice,
        device: DeviceSlice,
        collapsed: CollapsedSlice,
        sider: SideBarSlice
    }
})