import Container from "../../components/Container/Container";
import {useContext, useEffect, useState} from "react";
import {
    Button,
    Descriptions,
    Form,
    message,
    Modal,
    Popconfirm,
    Space,
    Statistic,
    Table,
    Tag,
} from "antd";
import Search from "../../components/Search/Search";
import getPayIns from "../../api/admin/pay-in/getPayIns";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {convertUnixTimestampToDateTime, formatDateTime, numberFormat} from "../../helpers/helpers";
import TextCopy from "../../components/TextCopy";
import {EditOutlined, ExportOutlined, ReloadOutlined, TransactionOutlined} from "@ant-design/icons";
import FormDrawer from "../../components/FormDrawer/FormDrawer";
import update from "../../api/admin/pay-in/update";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import getBanks from "../../api/bank/getBanks";
import LoadingContext from "../../contexts/LoadingContext";
import getBankAccountTransaction from "../../api/bank-account/getBankAccountTransaction";
import BankAccountTransaction from "../../components/BankAccount/BankTransaction/BankAccountTransaction";
import callback from "../../api/admin/pay-in/callback";
import getTransaction from "../../api/admin/pay-in/getTransaction";
import getListTransaction from "../../api/admin/pay-in/getListTransaction";
import dayjs from "dayjs";
import searchPartner from "../../api/partner/searchPartner";

function PayIn() {
    const accessToken = useAuthHeader();
    const [tableLoading,setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [openDrawerEdit,setOpenDrawerEdit] = useState(false);
    const [formEdit] = Form.useForm();
    const [tempRecord,setTempRecord] = useState({});
    const [loadingSubmit,setLoadingSubmit] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [options, setOptions] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    const [dataTransaction, setDataTransaction] = useState([]);
    const [openModalTransaction, setOpenModalTransaction] = useState(false);
    const [recordLoading, setRecordLoading] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [transaction, setTransaction] = useState({});
    const [listTransaction, setListTransaction] = useState([]);
    const [optionQuery,setOptionQuery] = useState([]);
    const [query,setQuery] = useState('');
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0,
        totalActualAmount: 0,
        totalTransactionAmount: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items =  [
        {
            name: 'userId',
            type: 'search',
            placeholder: 'Nhập tên đối tác',
            options: optionQuery.map((item) => {
                return {
                    value: item.userId,
                    text: item.name
                }
            }),
            onSearch: (value) => {
                setQuery(value)
            }
        },
        {
            name: 'uuid',
            placeholder: 'Mã đơn',
            type: 'text'
        },
        {
            name: 'requestId',
            placeholder: 'Mã tra soát',
            type: 'text'
        },
        {
            name: 'bankTransactionId',
            placeholder: 'Mã giao dịch ngân hàng',
            type: 'text'
        },
        {
            name: 'code',
            placeholder: 'Mã khớp',
            type: 'text'
        },
        {
            name: 'createdAt',
            placeholder: 'Thời gian',
            type: 'date'
        },
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Đối tác',
            dataIndex: 'username',
            align: 'center',
        },
        {
            title: 'Mã tra soát',
            dataIndex: 'requestId',
            width: '150px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center',
            filterMode: 'tree',
            filters: options
        },
        {
            title: 'Tài khoản nhận',
            dataIndex: 'accNo',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Mã khớp',
            dataIndex: 'code',
            width: '150px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền tạo',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Thực chuyển',
            dataIndex: 'transactionAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            render: (value) => {
                return numberFormat(value, 2)
            }
        },
        {
            title: 'Tiền cộng',
            dataIndex: 'actualAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Mã giao dịch',
            dataIndex: 'bankTransactionId',
            width: '180px',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <TextCopy value={value}/>
                }
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'state',
            align: 'center',
            filters: [
                {
                    text: 'Đang chờ',
                    value: 'pending'
                },
                {
                    text: 'Thành công',
                    value: 'processed'
                },
                {
                    text: 'Lỗi',
                    value: 'failed'
                },
                {
                    text: 'Cảnh báo',
                    value: 'warning'
                }
            ],
            render: (value) => {
                const statusMap = {
                    pending: { color: 'warning', label: 'Đang chờ' },
                    processed: { color: 'success', label: 'Thành công' },
                    failed: { color: 'error', label: 'Lỗi' },
                    warning: { color: 'warning', label: 'Cảnh báo'}
                };
                const { color, label } = statusMap[value] || { color: 'default', label: value };
                return <Tag color={color}>{label}</Tag>;
            }
        },
        {
            title: 'Trạng thái CB',
            dataIndex: 'isCallback',
            align: 'center',
            filters: [
                {
                    text: 'Đã callback',
                    value: true
                },
                {
                    text: 'Chưa callback',
                    value: false
                },
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã CALLBACK</Tag>
                }
                return <Tag color={'yellow'}>Chưa CALLBACK</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'expiredAt',
            align: 'center',
            render: (value,record) => {
                if (record.state !== "pending") {
                    return <Tag color={'success'}>
                        <Statistic.Countdown value={0} valueStyle={{
                            fontSize: '13px'
                        }}/>
                    </Tag>
                }
                const deadline = value * 1000;
                if (Date.now() > deadline) {
                    return <Tag color={'error'}>Hết hạn</Tag>
                }
                return (
                    <Tag color={'warning'}>
                        <Statistic.Countdown value={deadline} valueStyle={{
                            fontSize: '13px'
                        }}/>
                    </Tag>
                )
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value)  => {
                return (
                    <Tag>{formatDateTime(value)}</Tag>
                )
            }
        },
        {
            title: 'Thời gian hoàn thành',
            dataIndex: 'completedAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value)  => {
                if (value) {
                    return (
                        <Tag>{formatDateTime(value)}</Tag>
                    )
                }
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (_,record) => {
                return (
                    <Space>
                        {record.status === false && <Button onClick={() => handleEditPayIn(record)} icon={<EditOutlined/>} title={'Chỉnh sửa đơn'}/>}
                        {record.state === 'processed' && <Button loading={!!recordLoading[record.uuid]} onClick={() => handleGetTransaction(record.uuid)} type={'primary'} ghost={true} icon={<FontAwesomeIcon icon={faInfo}/>} title={'Chi tiết giao dịch'}/>}
                        <Popconfirm
                            title="Re callback"
                            description="Bạn muốn gửi lại yêu cầu callback?"
                            onConfirm={() => handleCallbackPayIn(record.uuid)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button loading={!!recordLoading[record.uuid]} type={'primary'} icon={<FontAwesomeIcon icon={faRightToBracket}/>} title={'Callback'} ></Button>
                        </Popconfirm>
                        <Button loading={!!recordLoading[record.uuid]} icon={<TransactionOutlined/>} title={'Xem giao dịch'} onClick={() => handleGetBankAccountTransaction(record.bankAccountId)}/>
                    </Space>
                )
            }
        }
    ];

    const handleGetTransaction = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await getTransaction(accessToken, uuid);
        if (response.success) {
            setIsModalVisible(true);
            setTransaction(response.data);
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleCallbackPayIn = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await callback(accessToken, uuid);
        if (response.success) {
            message.success('Gửi yêu cầu callback thành công');
        } else {
            message.error(response.message);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleEditPayIn = async (record) => {
        if (record.status) {
            setOpenDrawerEdit(true);
            setTempRecord(record);
        }
        else {
            setLoading(true);
            const response = await getListTransaction(accessToken, record.uuid);
            if (response.success) {
                setOpenDrawerEdit(true);
                setTempRecord(record);
                const transactions = response.data.map(item => {
                    return {
                        label: `[${item.transactionId}] - ${item.content} (${convertUnixTimestampToDateTime(item.time)})`,
                        value: item.transactionId
                    }
                })
                setListTransaction(transactions);
            }
            else {
                message.error(response.message);
            }
            setLoading(false);
        }
    }

    const handleSearch = () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getPayIns(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee,
                totalActualAmount: response.data.totalActualAmount,
                totalTransactionAmount: response.data.totalTransactionAmount
            })
        }
        setTableLoading(false);
    }

    const handleGetBankAccountTransaction = async (id) => {
        setLoading(true);
        const response = await getBankAccountTransaction(accessToken, id);
        if (response.success) {
            setOpenModalTransaction(true);
            setDataTransaction(response.data);
        }
        setLoading(false);
    }

    const handleSubmitEdit = async (values) => {
        setLoadingSubmit(true);
        const response = await update(accessToken,values);
        if (response.success) {
            const newData = [...dataSource];
            const index = dataSource.findIndex(item => item.uuid === values.uuid);
            if (index !== -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...response.data,
                });
                setDataSource(newData);
            }
            message.success('Cập nhật thành công');
        }
        else {
            message.error(response.message);
        }
        setLoadingSubmit(false);
    }

    useEffect(() => {
        setLoading(true);
        const fetchBanks = async () => {
            const response = await getBanks(accessToken);
            if (response.success) {
                setOptions(response.data.map(item => ({
                    text:  item.displayName,
                    value: item.bankName
                })))
            }
        }
        fetchBanks().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetchDataTable().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    useEffect(() => {
        formEdit.resetFields();
    }, [tempRecord]);

    useEffect(() => {
        setLoading(true);
        const fetchPartners = async () => {
            const response = await searchPartner(accessToken,query);
            if (response.success) {
                setOptionQuery(response.data)
            }
        }
        fetchPartners().finally(setLoading(false));
    }, [query])

    return (
        <Container>
            {contextHolder}
            <Space
                style={{
                    marginBottom: '20px'
                }}
            >
                <Button onClick={fetchDataTable} icon={<ReloadOutlined/>} title={'Làm mới'}>Làm mới</Button>
                <Button type={'primary'} icon={<ExportOutlined/>} title={'Xuất file'}>Xuất file</Button>
            </Space>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                scroll={{
                    x: 2200,
                }}
                dataSource={dataSource}
                loading={tableLoading}
                bordered={true}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6}>
                                Tổng tiền
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalTransactionAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmount)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
            <Form
                form={formEdit}
                onFinish={handleSubmitEdit}
            >
                <FormDrawer
                    title={'Chỉnh sửa đơn'}
                    open={openDrawerEdit}
                    onClose={() => setOpenDrawerEdit(false)}
                    onSubmit={formEdit.submit}
                    loading={loadingSubmit}
                    width={800}
                    items={[
                        {
                            name: 'uuid',
                            placeholder: 'Nhập đơn',
                            type: 'text',
                            label: 'Mã đơn',
                            disabled: true,
                            initValue: tempRecord.uuid
                        },
                        {
                            name: 'requestId',
                            placeholder: 'Nhập mã tra soát',
                            type: 'text',
                            label: 'Mã tra soát',
                            disabled: true,
                            initValue: tempRecord.requestId
                        },
                        {
                            name: 'code',
                            placeholder: 'Nhập mã khớp',
                            type: 'text',
                            label: 'Mã khớp',
                            disabled: true,
                            initValue: tempRecord.code
                        },
                        {
                            name: 'amount',
                            placeholder: 'Nhập số tiền yêu cầu',
                            type: 'number',
                            label: 'Số tiền yêu cầu',
                            disabled: true,
                            initValue: tempRecord.amount
                        },
                        {
                            name: 'accNo',
                            placeholder: 'Nhập tài khoản',
                            type: 'text',
                            label: 'Tài khoản',
                            disabled: true,
                            initValue: tempRecord.accNo
                        },
                        {
                            name: 'transactionAmount',
                            placeholder: 'Nhập số tiền thực chuyển',
                            type: 'number',
                            label: 'Số tiền thực chuyển',
                            initValue: tempRecord.transactionAmount,
                            disabled: true,
                        },
                        {
                            name: 'bankTransactionId',
                            placeholder: 'Chọn mã giao dịch ngân hàng',
                            type: 'select',
                            label: 'Mã giao dịch ngân hàng',
                            required: true,
                            options: listTransaction
                        },
                        {
                            name: 'note',
                            placeholder: 'Nhập ghi chú',
                            type: 'text',
                            label: 'Ghi chú',
                            initValue: tempRecord.note
                        }
                    ]}
                />
            </Form>
            <BankAccountTransaction
                open={openModalTransaction}
                setOpen={setOpenModalTransaction}
                dataSource={dataTransaction}
            />
            <Modal
                title="Thông tin giao dịch"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={800}
            >
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Số tiền">{numberFormat(transaction.amount)}</Descriptions.Item>
                    <Descriptions.Item label="Mã giao dịch">
                        <TextCopy value={transaction.transactionId}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phí">{transaction.fee}</Descriptions.Item>
                    <Descriptions.Item label="Nội dung">{transaction.content}</Descriptions.Item>
                    <Descriptions.Item label="Thời gian">{convertUnixTimestampToDateTime(transaction.time)}</Descriptions.Item>
                </Descriptions>
            </Modal>
        </Container>
    )
}

export default PayIn;