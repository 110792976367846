import createRefresh from "react-auth-kit/createRefresh";
import axios from "../api/axios";
export const refreshToken = createRefresh({
    interval: 30,
    refreshApiCallback: async (param) => {
        try {
            const response = await axios.post("auth/refresh", {
                accessToken: param.authToken
            }, {
                headers: {'Authorization': `Bearer ${param.authToken}`}
            });
            const {data} = response.data;
            return {
                isSuccess: true,
                newAuthToken: data.accessToken,
                newAuthTokenExpireIn: data.expiredAt,
                newRefreshTokenExpiresIn: 60
            }
        }
        catch(error){
            console.log(error)
            return {
                isSuccess: false
            }
        }
    }
})