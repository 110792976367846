import {createSlice} from "@reduxjs/toolkit";

export const SideBarSlice = createSlice({
    name: "sidebar",
    initialState: {
        defaultSelectedKey: JSON.parse(localStorage.getItem("sider"))?.defaultSelectedKey,
        defaultOpenKey: JSON.parse(localStorage.getItem("sider"))?.defaultOpenKey,
    },
    reducers: {
        toggleSelectedKey: (state, action) => {
            state.defaultSelectedKey = action.payload.defaultSelectedKey;
            const dataLocalStorage = JSON.parse(localStorage.getItem("sider")) ?? {};
            const dataSaveLocalStorage = {
                ...dataLocalStorage,
                defaultSelectedKey: action.payload.defaultSelectedKey
            }
            localStorage.setItem("sider", JSON.stringify(dataSaveLocalStorage));
        },
        toggleOpenKey: (state, action) => {
            state.defaultOpenKey = action.payload.defaultOpenKey;
            const dataLocalStorage = JSON.parse(localStorage.getItem("sider")) ?? {};
            const dataSaveLocalStorage = {
                ...dataLocalStorage,
                defaultOpenKey: action.payload.defaultOpenKey
            }
            localStorage.setItem("sider", JSON.stringify(dataSaveLocalStorage));
        }
    }
});

export const { toggleSelectedKey, toggleOpenKey } = SideBarSlice.actions;

export default SideBarSlice.reducer;