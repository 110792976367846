import axios from "../axios";

const searchTransactions = async function (accessToken, uuid ,params = {}) {
    try {
        const response = await axios.post('/bank-transaction/searchTransactions', params, {
            headers: {
                'Authorization': accessToken
            },
            params: {
                uuid
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default searchTransactions;