import axios from "../../axios";

const getIpAddress = async function (accessToken,userId = {}) {
    try {
        const response = await axios.get('partner/ip-address/getIpAddress' ,{
            headers: {
                'Authorization': accessToken
            },
            params: {
                user_id: userId
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getIpAddress;