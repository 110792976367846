import axios from "../axios";

const verify = async function (deviceId, otp) {
    try {
        const response = await axios.post('/auth/verify', {
            deviceId,
            otp,
        }, {
            headers: {
                'Authorization': `Bearer`
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status,
            message: e.message
        }
    }
}

export default verify;