import {Flex, Tag} from "antd";
import {numberFormat} from "../../helpers/helpers";

function AmountLimit({amount,total}) {
    const remaining = total - amount;
    return (
        <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
            <Tag color={'blue'}>{numberFormat(amount)}</Tag>
            <Tag color={'magenta'}>{numberFormat(remaining)}</Tag>
        </Flex>
    );
}

export default AmountLimit;