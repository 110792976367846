import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {useContext, useEffect, useState} from "react";
import LoadingContext from "../contexts/LoadingContext";
import {Form, Table, Tag} from "antd";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import getStatistics from "../api/statistic/getStatistics";
import Container from "../components/Container/Container";
import Search from "../components/Search/Search";

function Statistic() {
    const accessToken  = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [expandDataSource, setExpandDataSource] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'date',
            type: 'day',
        }
    ];

    const columns = [
        {
            title: 'Loại',
            dataIndex: 'type',
            align: 'center',
            render: (value) => {
                if (value === 'in') {
                    return <Tag color={'green'}>Tiền In</Tag>
                }
                return <Tag color={'blue'}>Tiền Out</Tag>
            }
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return <Tag color={'blue'}>{value}</Tag>
            }
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'totalAmount',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng phí',
            dataIndex: 'totalFee',
            align: 'center',
            width: '240px',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Tổng số dư',
            dataIndex: 'totalBalance',
            align: 'center',
            render: (_, record) => {
                if (record.type === 'in') {
                    return <Tag color={'green'}>{'+' + numberFormat(record.totalAmount - record.totalFee)}</Tag>
                }
                return <Tag color={'red'}>{'-' + numberFormat(record.totalAmount + record.totalFee)}</Tag>;
            }
        }
    ];

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getStatistics(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchData().finally();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                columns={columns}
                dataSource={dataSource}
                loading={tableLoading}
                bordered={true}
                scroll={{
                    x: 1600
                }}
                onChange={handleTableChange}
                pagination={null}
                summary={(data) => {
                    const totalAmount = data.reduce((total, user) => {
                        return total + parseInt(user.totalAmount, 10);
                    }, 0);
                    const totalFee = data.reduce((total, user) => {
                        return total + parseInt(user.totalFee, 10);
                    }, 0);
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(totalFee)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default Statistic;