import classnames from "classnames/bind";
import styles from './ItemIcon.module.scss';

const cx = classnames.bind(styles)
function ItemIcon({children}) {
    return (
        <div className={cx('wrapper')}>{children}</div>
    )
}

export default ItemIcon;