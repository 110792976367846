import {
    Button,
    Dropdown,
    Flex,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm,
    QRCode,
    Select,
    Space,
    Table,
    Tag,
    Typography
} from "antd";
import { formatDateTime, numberFormat} from "../../../helpers/helpers";
import AmountLimit from "../../AmountLimit/AmountLimit";
import CountLimit from "../../CountLimit/CountLimit";
import BankAccountType from "../BankAccountType/BankAccountType";
import {useContext, useEffect, useState} from "react";
import getBankAccounts from "../../../api/bank-account/getBankAccounts";
import handle from "../../../api/bank-account/handle";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowsRotate,
    faMoneyBillTransfer, faRegistered,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import ItemDropDown from "../../TopMenu/ItemDropDown/ItemDropDown";
import {
    AccountBookOutlined,
    CheckOutlined, DeleteOutlined,
    EditOutlined,
    LoginOutlined,
    MobileOutlined, PlusOutlined,
    QrcodeOutlined, SearchOutlined,
    TransactionOutlined
} from "@ant-design/icons";
import EditTableCell from "../../Table/EditTableCell/EditTableCell";
import updateBankAccount from "../../../api/bank-account/updateBankAccount";
import Search from "../../Search/Search";
import TextCopy from "../../TextCopy";
import FormDrawer from "../../FormDrawer/FormDrawer";
import getBankTransfer from "../../../api/bank-account/getBankTransfer";
import LoadingContext from "../../../contexts/LoadingContext";
import transfer from "../../../api/bank-account/transfer";
import getBenchName from "../../../api/bank-account/getBenchName";
import getBankAccountDevice from "../../../api/bank-account/device/getBankAccountDevice";
import updateBankAccountDevice from "../../../api/bank-account/device/updateBankAccountDevice";
import BankAccountTransaction from "../BankTransaction/BankAccountTransaction";
import ReadMoreText from "../../ReadMoreText";
import requestOtp from "../../../api/bank-account/requestOtp";
import submitOtp from "../../../api/bank-account/submitOtp";
import deleteBankAccount from "../../../api/bank-account/deleteBankAccount";
import getBankAccountBusiness from "../../../api/bank-account/getBankAccountBusiness";
import confirmBankAccountApprove from "../../../api/bank-account/confirmBankAccountApprove";
import getBankAccountPrivate from "../../../api/bank-account/bank-account-private/getBankAccountPrivate";
import searchPartner from "../../../api/partner/searchPartner";
import addPartner from "../../../api/bank-account/bank-account-private/addPartner";
import deleteBankAccountPrivate from "../../../api/bank-account/bank-account-private/deleteBankAccountPrivate";
import {useStyle} from "../../../hook/useStyle";

function BankAccountTable({ banks, onRefresh, types }) {
    const [tableLoading,setTableLoading] = useState(false);
    const [dataSource,setDataSource] = useState([]);
    const accessToken  = useAuthHeader();
    const [openModal,setOpenModal] = useState(false);
    const [qrCode,setQrCode] = useState('');
    const [icon, setIcon] = useState('');
    const [openModalTransaction,setOpenModelTransaction] = useState(false);
    const [dataTransaction,setDataTransaction] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [formSearch] = Form.useForm();
    const [openDrawerTransfer,setOpenDrawerTransfer] = useState(false);
    const [bankTransfer,setBankTransfer] = useState([]);
    const [uuid,setUuid] = useState('');
    const [bankAccountId, setBankAccountId] = useState('');
    const [formTransfer] = Form.useForm();
    const [formDevice] = Form.useForm();
    const [openModalDevice, setOpenModalDevice] = useState(false);
    const {setLoading} = useContext(LoadingContext);
    const [dataTransfer,setDataTransfer] = useState({});
    const [openModalOtp, setOpenModalOtp] = useState(false);
    const [formOtp] = Form.useForm();
    const [widthTable, setWidthTable] = useState(2800);
    const [recordLoading, setRecordLoading] = useState({});
    const [openModalApprove, setOpenModalApprove] = useState(false);
    const [optionApproves, setOptionApproves] = useState([]);
    const [bankAccountIdApprove, setBankAccountIdApprove] = useState(null);
    const [uuidApprove, setUuidApprove] = useState('');
    const [openModalBankPrivate, setOpenModalBankPrivate] = useState(false);
    const [dataBankPrivate, setDataBankPrivate] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [optionQuery,setOptionQuery] = useState([]);
    const [query,setQuery] = useState('');
    const [formBankPrivate] = Form.useForm();
    const [bankAccountIdPrivate, setBankAccountIdPrivate] = useState('');
    const { styles } = useStyle();
    const [summaryData, setSummaryData] = useState({
        totalBalance: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: (!types.includes('wallet') ? 50 : 10),
        }
    });

    const items =  [
        {
            name: 'username',
            placeholder: 'Tên đăng nhập',
            type: 'text'
        },
        {
            name: 'accountName',
            placeholder: 'Tên tài khoản',
            type: 'text'
        },
        {
            name: 'accountNumber',
            placeholder: 'Số tài khoản',
            type: 'text'
        }
    ]

    const mergedItems = [
        {
            key: 'GET_BALANCE',
            label: (
                <ItemDropDown icon={<FontAwesomeIcon icon={faArrowsRotate}/>}>
                    Làm mới số dư
                </ItemDropDown>
            ),
        },
        {
            key: 'GET_TRANSACTION',
            label: (
                <ItemDropDown icon={<TransactionOutlined/>}>
                    Truy vấn giao dịch
                </ItemDropDown>
            ),
        },
        {
            key: 'RE_LOGIN',
            label: (
                <ItemDropDown icon={<LoginOutlined/>}>
                    Đăng nhập
                </ItemDropDown>
            )
        },
        {
            key: 'REGISTER_SMART_OTP',
            type: 'wallet',
            label: (
                <ItemDropDown icon={<FontAwesomeIcon icon={faRegistered}/>}>
                    Đăng ký smart OTP
                </ItemDropDown>
            )
        },
        {
            key: 'SHOW_QR',
            label: (
                <ItemDropDown icon={<QrcodeOutlined/>}>
                    QR Code
                </ItemDropDown>
            )
        }
    ]

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'bankName',
            filters: banks,
            filterMode: 'tree',
            align: 'center',
            fixed: 'left',
            render: (value) => {
                return (
                    <Tag>{value}</Tag>
                )
            }
        },
        {
            title: 'Đối tác riêng',
            dataIndex: 'bankAccountPrivates',
            align: 'center',
            width: '200px',
            hidden: types.includes('wallet'),
            render: (value, record) => {
                return (
                    <Space>
                        {value.length === 0 && (
                            <Tag color={'default'}>ALL</Tag>
                        )}
                        {value.map((item, index) => (
                            <Tag color={'orange'} key={index}>{item.username}</Tag>
                        ))}
                        <Button onClick={() => handleClickEditBankPrivate(record)} type={'primary'} size={'small'} icon={<EditOutlined/>} title={'Sửa đối tác'}/>
                        <Button onClick={() => handleClickAddBankPrivate(record)} size={'small'} icon={<PlusOutlined/>} title={'Thêm đối tác'}/>
                    </Space>
                )
            }
        },
        {
            title: 'Loại tài khoản',
            dataIndex: 'type',
            align: 'center',
            filterMode: 'tree',
            editable: true,
            inputType: 'select',
            width: '120px',
            fixed: 'left',
            filters: [
                {
                    text: 'Nhận tiền',
                    value: 'in'
                },
                {
                    text: 'Chuyển tiền',
                    value: 'out'
                },
                {
                    text: 'Nhận và chuyển',
                    value: 'in_out'
                },
                {
                    text: 'Cho thuê',
                    value: 'hook'
                },
                {
                    text: 'Nhận tiền dồn',
                    value: 'main'
                }
            ],
            render: (value) => {
                return <BankAccountType type={value}/>
            }
        },
        {
            title: 'Cấp độ',
            dataIndex: 'level',
            align: 'center',
            width: '100px',
            hidden: !types.includes('wallet'),
            render: (value) => {
                const colorLevel = {
                    0 : 'red',
                    1 : 'red',
                    2 : 'red',
                    3 : 'red',
                    4 : 'red',
                    5 : 'green'
                }
                return <Tag color={colorLevel[value]}>Cấp {value}</Tag>
            }
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            width: '150px',
            align: 'center',
            hidden: false,
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Thông tin',
            dataIndex: 'accountInfo',
            align: 'center',
            width: '250px',
            fixed: 'left',
            render: (_,record) => {
                return (
                    <Flex vertical={true} justify={'center'} align={'center'} gap={'small'}>
                        <ReadMoreText text={record.accountName} maxLength={15}/>
                        <Typography.Paragraph
                            copyable={{
                                text: `${record.displayName}\n${record.accountNumber}\n${record.accountName}`
                            }}
                            style={{
                                margin: 0
                            }}
                        ><Tag>{record.accountNumber}</Tag></Typography.Paragraph>
                    </Flex>
                )
            }
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            align: 'center',
            width: '130px',
            render: (value,record) => {
                return numberFormat(record.balance);
            },
            sorter: true
        },
        {
            title: 'Tổng nạp (ngày)',
            dataIndex: 'dailyReceiveAmount',
            width: '200px',
            sorter: true,
            render: (value,record) => {
                return <AmountLimit amount={record.dailyReceiveAmount} total={record.maxAmountLimitPerDay}/>
            }
        },
        {
            title: 'Tổng chuyển (ngày)',
            dataIndex: 'dailyTransferAmount',
            width: '200px',
            sorter: true,
            render: (value, record) => {
                return <AmountLimit amount={record.dailyTransferAmount} total={record.maxAmountLimitTransferPerDay}/>
            }
        },
        {
            title: 'Tổng nạp (tháng)',
            dataIndex: 'monthlyReceiveAmount',
            width: '200px',
            sorter: true,
            render: (value,record) => {
                return <AmountLimit amount={record.monthlyReceiveAmount} total={record.maxAmountLimitPerMonth}/>
            }
        },
        {
            title: 'Tổng chuyển (tháng)',
            dataIndex: 'monthlyTransferAmount',
            width: '200px',
            sorter: true,
            render: (value, record) => {
                return <AmountLimit amount={record.monthlyTransferAmount} total={record.maxAmountLimitTransferPerMonth}/>
            }
        },
        {
            title: 'Số GD nhận (ngày)',
            dataIndex: 'dailyTotalReceives',
            hidden: true,
            render: (value,record) => {
                return <CountLimit count={record.dailyTotalReceives} total={record.dailyReceiveLimit}/>
            },
            sorter: (a,b) => {
                return a.dailyTotalRecevies - b.dailyTotalRecevies
            }
        },
        {
            title: 'Số GD chuyển (ngày)',
            dataIndex: 'dailyTotalTransfers',
            hidden: true,
            render: (value,record) => {
                return <CountLimit count={record.dailyTotalTransfers} total={record.dailyTransferLimit}/>
            },
            sorter: (a,b) => {
                return a.dailyTotalTransfers - b.dailyTotalTransfers
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: '120px',
            filters: [
                {
                    text: 'Đóng',
                    value: false
                },
                {
                    text: 'Mở',
                    value: true
                }
            ],
            align: 'center',
            render: (value) => {
                if (value === true) {
                    return <Tag color={'green'}>OKE</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Tự động rút',
            dataIndex: 'autoWithdrawalEnabled',
            width: '120px',
            editable: true,
            inputType: 'switch',
            filters: [
                {
                    text: 'Đóng',
                    value: false
                },
                {
                    text: 'Mở',
                    value: true
                }
            ],
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Mở</Tag>
                }
                return <Tag color={'red'}>Đóng</Tag>
            }
        },
        {
            title: 'Hoạt động',
            dataIndex: 'state',
            editable: true,
            inputType: 'switch',
            align: 'center',
            width: '150px',
            filters: [
                {
                    text: 'Tắt',
                    value: 'disabled'
                },
                {
                    text: 'Bật',
                    value: 'active'
                }
            ],
            render: (value) => {
                if (value === 'disabled') {
                    return <Tag color={'red'}>Không hoạt động</Tag>
                }
                return <Tag color={'green'}>Hoạt động</Tag>
            }
        },
        {
            title: 'Đăng nhập tự động',
            dataIndex: 'autoReLoginEnabled',
            editable: true,
            inputType: 'switch',
            align: 'center',
            width: '150px',
            filters: [
                {
                    text: 'Tắt',
                    value: false
                },
                {
                    text: 'Bật',
                    value: true
                }
            ],
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Hoạt động</Tag>
                }
                return <Tag color={'red'}>Không hoạt động</Tag>
            }
        },
        {
            title: 'Tình trạng',
            dataIndex: 'stateAuth',
            inputType: 'switch',
            align: 'center',
            width: '150px',
            filters: [
                {
                    text: 'Bình thường',
                    value: 'none'
                },
                {
                    text: 'Bị khóa',
                    value: 'locked'
                },
                {
                    text: 'Xác thực OTP',
                    value: 'otp'
                },
                {
                    text: 'Sai mật khẩu',
                    value: 'password'
                }
            ],
            render: (value) => {
                if (value === 'locked') {
                    return <Tag color={'red-inverse'}>Bị khóa</Tag>
                } else if (value === 'otp') {
                    return <Tag color={'orange'}>Xác thực OTP</Tag>
                } else if (value === 'password') {
                    return <Tag color={'orange'}>Sai mật khẩu</Tag>
                } else {
                    return <Tag color={'green'}>Bình thường</Tag>
                }
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_,record) => {
                const items = mergedItems.map((col) => {
                    if ((col.type && types.includes(col.type)) || !col.type) {
                        return {
                            ...col,
                            onClick: () => handleAccount(record.uuid,col.key)
                        }
                    }
                });
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(record.uuid)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancel}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Space>
                        <Dropdown.Button
                            placement={'bottom'}
                            menu={{
                                items
                            }}
                            type={'default'}
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                            title={'Chỉnh sửa'}
                        >
                            <EditOutlined/>
                        </Dropdown.Button>
                        <Button onClick={() => handleClickTransfer(record)} icon={<FontAwesomeIcon icon={faMoneyBillTransfer}/>} title={'Chuyển tiền'}/>
                        {record.bankType !== 'wallet' && (
                            <Button loading={!!recordLoading[record.uuid]} onClick={() => handleClickDevice(record)} type={'primary'} icon={<MobileOutlined/>} title={'Thiết bị'}/>
                        )}
                        {record.bankType === 'business' && (
                            <Button loading={!!recordLoading[record.uuid]} onClick={() => handleOpenApprove(record.uuid)} type={'primary'} icon={<AccountBookOutlined/>} title={'Tài khoản duyệt'}/>
                        )}
                        <Popconfirm
                            title="Xóa tài khoản"
                            description="Bạn muốn xóa tài khoản này?"
                            onConfirm={() => handleDeleteBankAccount(record.uuid)}
                            okText="Yes"
                            cancelText="No"
                            icon={<CheckOutlined/>}
                        >
                            <Button loading={!!recordLoading[record.uuid]} danger={true} type={'dashed'} icon={<FontAwesomeIcon icon={faTrash}/>} title={'Xóa tài khoản'}/>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ];

    const itemTransfer = [
        {
            name: 'accountNumber',
            placeholder: 'Nhập số tài khoản',
            type: 'text',
            label: 'Số tài khoản',
            required: true
        },
        {
            name: 'amount',
            placeholder: 'Nhập số tiền',
            type: 'number',
            label: 'Số tiền',
            required: true
        },
        {
            name: 'bankCode',
            placeholder: 'Chọn ngân hàng',
            type: 'select',
            label: 'Ngân hàng nhận',
            required: true,
            options: (bankTransfer).map((item) => {
                return {
                    value:  item.bankCode,
                    label: `${item.bankName} - ${item.displayName} (${item.shortName})`
                }
            })
        },
        {
            name: 'accountName',
            placeholder: 'Nhập tên người nhận',
            type: 'text',
            label: 'Tên người nhận',
            required: true,
            disabled: true
        },
        {
            name: 'comment',
            placeholder: 'Nhập nội dung',
            type: 'text',
            label: 'Nội dung',
            required: false
        },
        {
            name: 'challenge',
            placeholder: 'Mã giao dịch để lấy OTP',
            type: 'text',
            label: 'Mã giao dịch',
            disabled: true
        },
        {
            name: 'otp',
            placeholder: 'Nhập mã OTP',
            type: 'text',
            label: 'Mã OTP',
        }
    ];

    const handleClickTransfer = (record) => {
        setOpenDrawerTransfer(true);
        setUuid(record.uuid);
    }
    
    const handleSearchPartner = (value) => {
        setQuery(value);
    }

    const handleClickAddBankPrivate = (record) => {
        setIsModalVisible(true);
        setBankAccountIdPrivate(record.bankAccountId);
    }

    const handleSubmitAddPartner = async (values) => {
        const data = {
            bankAccountId: bankAccountIdPrivate,
            userIds: values.userIds
        }
        const response = await addPartner(accessToken, data);
        if (response.success) {
            message.success('Thêm đối tác cho tài khoản ngân hàng thành công.');
        } else {
            message.error(response.message);
        }
    }

    const handleDeleteBankAccountPrivate = async (id) => {
        const response = await deleteBankAccountPrivate(accessToken, id);
        if (response.success) {
            message.success('Xóa thành công vui lòng làm mới lại trang.');
        } else {
            message.error(response.message);
        }
    }

    const handleClickEditBankPrivate = async (record) => {
        const response = await getBankAccountPrivate(accessToken, record.bankAccountId);
        if (response.success) {
            setOpenModalBankPrivate(true);
            setDataBankPrivate(response.data);
        }
    }

    const handleOpenApprove = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await getBankAccountBusiness(accessToken, uuid);
        if (response.success) {
            const optionData = response.data.map((item) => {
                return {
                    value: item.bankAccountId,
                    label: `(${item.bankName}) ${item.username} - ${item.accountNumber}`
                }
            });
            setUuidApprove(uuid);
            setOptionApproves(optionData);
            setOpenModalApprove(true);
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleConfirmApprove = async () => {
        const response = await confirmBankAccountApprove(accessToken, uuidApprove, bankAccountIdApprove);
        if (response.success) {
            message.success('Thêm tài khoản duyệt thành công');
        } else {
            message.error(response.message);
        }
    }

    const handleDeleteBankAccount = async (uuid) => {
        setRecordLoading(prev => ({ ...prev, [uuid]: true }));
        const response = await deleteBankAccount(accessToken, uuid);
        if (response.success) {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => uuid === item.uuid);
            newData.splice(index, 1);
            setDataSource(newData);
            message.success('Xóa tài khoản thành công');
        } else {
            message.error(response.message)
        }
        setRecordLoading(prev => ({ ...prev, [uuid]: false }));
    }

    const handleClickDevice = async (record) => {
        setRecordLoading(prev => ({ ...prev, [record.uuid]: true }));
        formDevice.setFieldsValue({
            deviceName: '',
            deviceId: '',
            pin: '',
            platformVersion: ''
        })
        const response = await getBankAccountDevice(accessToken, record.bankAccountId);
        if (response.success && response.data) {
            formDevice.setFieldsValue({
                deviceName: response.data?.deviceName,
                deviceId: response.data?.deviceId,
                pin: response.data?.pin,
                platformVersion: response.data?.platformVersion
            })
        }
        setOpenModalDevice(true);
        setBankAccountId(record.bankAccountId);
        setRecordLoading(prev => ({ ...prev, [record.uuid]: false }));
    }

    const handleSubmitOtp  = async (values) => {
        const response = await submitOtp(accessToken, uuid, values);
        if (response.success) {
            message.success('Đăng nhập thành công');
        } else {
            message.error(response.message)
        }
    }

    const handleClickRequestOtp = async () => {
        const response = await requestOtp(accessToken, uuid);
        if (response.success) {
            message.success('Vui lòng nhập mã OTP và xác nhận');
        }
        else {
            message.error(response.message);
        }
    }

    const handleSubmitDevice = async (values) => {
        setLoading(true);
        const response = await updateBankAccountDevice(accessToken, bankAccountId, values);
        if (response.success) {
            message.success('Cập nhật thiết bị thành công');
        }
        else {
            message.error(response.message);
        }
        setLoading(false);
    }

    const handleSubmitTransfer = async (values) => {
        try {
            setLoading(true);
            const response = await transfer(accessToken, uuid, {
                data: dataTransfer,
                ...values
            });
            if (response.success) {
                if (response.data.otp) {
                    setDataTransfer(response.data.data);
                    if (response.data.data.challenge) {
                        formTransfer.setFieldValue('challenge', response.data.data.challenge);
                    }
                    message.info('Vui lòng nhập mã OTP để xác nhận chuyển tiền');
                }
                else {
                    message.success('Chuyển tiền thành công!');
                }
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error('Đã xảy ra lỗi trong quá trình chuyển tiền.');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeTransfer = async (e) => {
        if (typeof e === 'string' || e.target.id === 'accountNumber') {
            const accountNumber = formTransfer.getFieldValue('accountNumber');
            const bankCode = formTransfer.getFieldValue('bankCode');
            if (bankCode && bankCode.length > 0) {
                const response = await getBenchName(accessToken, uuid, {
                    accountNumber: accountNumber,
                    bankCode: bankCode
                });
                if (response.success) {
                    formTransfer.setFieldValue('accountName', response.data.benchName);
                }
                else {
                    message.error(response.message);
                }
            }
        }
    }

    const cancel = () => {
        setEditingKey('');
    };

    const save =  async (uuid) => {
        setTableLoading(true);
        try {
            const row = await form.validateFields();
            const newData = [...dataSource];
            const index = newData.findIndex((item) => uuid === item.uuid);
            const data = {
                ...row,
                uuid: uuid
            };
            const response = await updateBankAccount(accessToken,data);
            if (response.success) {
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...response.data
                    });
                    setDataSource(newData);
                    setEditingKey('');
                } else {
                    console.log(row);
                    newData.push(row);
                    setDataSource(newData);
                    setEditingKey('');
                }
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setTableLoading(false);
        }
    }

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
            state: record.state === 'active'
        });
        setEditingKey(record.uuid);
    };

    const updateData = (uuid,data = {}) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => uuid === item.uuid);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...data
            });
            setDataSource(newData);
        } else {
            newData.push(data);
            setDataSource(newData);
        }
    }

    const handleAccount = (uuid, cmd) => {
        const fetchData = async () => {
            setTableLoading(true);
            try {
                const response = await handle(accessToken,uuid, cmd);
                if (response.success) {
                    switch (cmd) {
                        case 'GET_BALANCE':
                        case 'RE_LOGIN':
                            if (response.data.stateAuth === "otp") {
                                setOpenModalOtp(true);
                                setUuid(uuid);
                            }
                            updateData(uuid,response.data);
                            break;
                        case 'GET_TRANSACTION':
                            setOpenModelTransaction(true);
                            setDataTransaction(response.data);
                            setUuid(uuid);
                            break;
                        case 'SHOW_QR':
                            setOpenModal(true);
                            setQrCode(response.data.text);
                            setIcon(response.data.icon);
                            break;
                    }
                }
            }
            catch (e) {}
            setTableLoading(false);
        }
        fetchData();
    }

    const fetchData = async () => {
        setTableLoading(true);
        const response = await getBankAccounts(accessToken,{
            ...tableParams,
            types: types
        });
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalBalance: response.data.totalBalance
            })
        }
        setTableLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const isEditing = (record) => record.uuid === editingKey;

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                filters: col.filters ?? []
            }),
        };
    });

    const handleSearch = () => {
        const params = formSearch.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        });
    }

    useEffect(() => {
        fetchData().finally();
    }, [
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
        JSON.stringify(tableParams.pagination?.pageSize),
        JSON.stringify(tableParams.pagination?.current)
    ]);

    useEffect(() => {
        const params = tableParams;
        const pageSize = (!types.includes('wallet') ? 50 : 10);
        if (params.pagination.pageSize !== pageSize) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    pageSize: (!types.includes('wallet') ? 50 : 10)
                }
            });
        }
        setWidthTable((!types.includes('wallet') ? 2000 : 1800))
    }, [types]);

    useEffect(() => {
        onRefresh.current = fetchData;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getBankTransfer(accessToken);
            if (response.success) {
                setBankTransfer(response.data);
            }
        }
        fetchData().finally();
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchPartners = async () => {
            const response = await searchPartner(accessToken,query);
            if (response.success) {
                setOptionQuery(response.data)
            }
        }
        fetchPartners().finally(setLoading(false));
    }, [query])

    return (
        <>
            <Search
                form={formSearch}
                items={items}
                onSearch={handleSearch}
            />
            <Form
                form={form}
                component={false}
            >
                <Table
                    className={styles.customTable}
                    rowKey={'uuid'}
                    columns={mergedColumns}
                    // rowSelection={rowSelection}
                    bordered={true}
                    scroll={{
                        x: 'max-content',
                        y: 1000
                    }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    onChange={handleTableChange}
                    pagination={tableParams.pagination}
                    components={{
                        body: {
                            cell: EditTableCell
                        }
                    }}
                    summary={() => {
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5}>
                                    Tổng số dư
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                    <Tag color={'blue'}>{numberFormat(summaryData.totalBalance)}</Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                />
            </Form>
            <Modal
                open={openModal}
                onCancel={() => setOpenModal(false)}
                title={'Hiển thị mã QR CODE'}
                footer={null}
            >
                <Flex
                    justify={'center'}
                    align={'center'}
                >
                    <QRCode
                        value={qrCode}
                        size={300}
                        icon={icon}
                    />
                </Flex>
            </Modal>
            <BankAccountTransaction
                open={openModalTransaction}
                setOpen={setOpenModelTransaction}
                dataSource={dataTransaction}
                uuid={uuid}
            />
            <Modal
                open={openModalDevice}
                onOk={formDevice.submit}
                onCancel={() => setOpenModalDevice(false)}
                title={'Cài đặt thiết bị liên kết'}
                okText={'Xác nhận'}
            >
                <Form
                    layout={'vertical'}
                    form={formDevice}
                    onFinish={handleSubmitDevice}
                >
                    <Form.Item
                        name={'deviceName'}
                        label={'Tên thiết bị'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'deviceId'}
                        label={'ID thiết bị'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'platformVersion'}
                        label={'Phiên bản Android'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'pin'}
                        label={'Mã pin'}
                    >
                        <Input.Password/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Xác nhận mã OTP'}
                open={openModalOtp}
                onCancel={() => setOpenModalOtp(false)}
                okText={'Xác nhận'}
                onOk={formOtp.submit}
            >
                <Form
                    form={formOtp}
                    layout={'inline'}
                    onFinish={handleSubmitOtp}
                >
                    <Form.Item
                        name={'otp'}
                        style={{
                            width: '100%',
                            marginTop: '25px',
                            marginBottom: '25px'
                        }}
                    >
                        <Space.Compact
                            style={{
                                width: '100%'
                            }}
                        >
                            <Input
                                placeholder={'Nhập mã OTP'}
                            />
                            <Button type="primary" onClick={handleClickRequestOtp}>Gửi OTP</Button>
                        </Space.Compact>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Liên kết tài khoản duyệt'}
                open={openModalApprove}
                onOk={handleConfirmApprove}
                onCancel={() => setOpenModalApprove(false)}
                okText={'Xác nhận'}
                width={800}
            >
                <Select
                    style={{
                        width: '100%',
                    }}
                    onChange={(value) => setBankAccountIdApprove(value)}
                    options={optionApproves}
                />
            </Modal>
            <Modal
                title={'Thêm đối tác riêng'}
                open={isModalVisible}
                onOk={formBankPrivate.submit}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form
                    style={{
                        marginBottom: "16px"
                    }}
                    layout={'vertical'}
                    form={formBankPrivate}
                    onFinish={handleSubmitAddPartner}
                >
                    <Form.Item
                        name={'userIds'}
                        label={'Đối tác'}
                    >
                        <Select
                            mode={'multiple'}
                            showSearch
                            placeholder={'Đối tác'}
                            defaultActiveFirstOption={false}
                            suffixIcon={<SearchOutlined/>}
                            filterOption={false}
                            notFoundContent={null}
                            onSearch={handleSearchPartner}
                            options={(optionQuery || []).map((d) => ({
                                value: d.userId,
                                label: d.name,
                            }))}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Chỉnh sửa đối tác riêng'}
                open={openModalBankPrivate}
                onCancel={() => setOpenModalBankPrivate(false)}
                okText={'Xác nhận'}
                width={800}
            >
                <Table
                    dataSource={dataBankPrivate}
                    columns={[
                        {
                            title: 'Tên đối tác',
                            dataIndex: 'username',
                            align: 'center'
                        },
                        {
                            title: 'Ngày thêm',
                            dataIndex: 'createdAt',
                            align: 'center',
                            render: (value) => {
                                return formatDateTime(value)
                            }
                        },
                        {
                            title: 'Hành động',
                            dataIndex: 'operation',
                            render: (_, record) => {
                                return (
                                    <Space>
                                        <Popconfirm
                                            title={'Xóa bỏ'}
                                            description="Bạn muốn xóa đối tác sử dụng tài khoản ngân hàng này?"
                                            onConfirm={() => handleDeleteBankAccountPrivate(record.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger={true} icon={<DeleteOutlined/>} title={'Xóa'}/>
                                        </Popconfirm>
                                    </Space>
                                )
                            }
                        }
                    ]}
                />
            </Modal>
            <Form
                form={formTransfer}
                layout={'vertical'}
                onFinish={handleSubmitTransfer}
            >
                <FormDrawer
                    width={600}
                    title={'Chuyển tiền'}
                    open={openDrawerTransfer}
                    onClose={() => setOpenDrawerTransfer(false)}
                    items={itemTransfer}
                    onSubmit={formTransfer.submit}
                    onChange={handleChangeTransfer}
                />
            </Form>
        </>
    );
}

export default BankAccountTable;