import axios from "../axios";

const verifyGoogleSecret = async function (accessToken,otp) {
    try {
        const response = await axios.post('/user/verifyGoogleSecret', {
            otp,
        }, {
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default verifyGoogleSecret;