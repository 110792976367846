import axios from "../../axios";

const exportFile = async (accessToken, params, filename) => {
    try {
        const response = await axios.get('/admin/pay-out/exportFile', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            params: params,
            responseType: 'blob',
        });
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        // Thêm liên kết vào DOM và click để tải xuống
        document.body.appendChild(link);
        link.click();
        // Xóa liên kết khỏi DOM
        document.body.removeChild(link);
        return response.data;
    }
    catch (e) {
        console.log(e);
        return {
            success: false,
            message: e.message
        }
    }
}

export default exportFile;