import classnames from "classnames/bind";
import styles from './ItemLabel.module.scss'

const cx = classnames.bind(styles);
function ItemLabel({children}) {
    return (
        <p className={cx('wrapper')}>
            {children}
        </p>
    )
}

export default ItemLabel;