import Container from "../components/Container/Container";
import {
    Button,
    Card,
    Flex,
    Form,
    Image,
    Space,
    Table,
    Tag
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import getBanks from "../api/bank/getBanks";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {numberFormat} from "../helpers/helpers";
import {BankOutlined, EditOutlined, WalletOutlined} from "@ant-design/icons";
import EditTableCell from "../components/Table/EditTableCell/EditTableCell";
import updateBank from "../api/bank/updateBank";

function Bank() {
    const [editingKey, setEditingKey] = useState('');
    const [dataSource,setDataSource] = useState([]);
    const accessToken = useAuthHeader();
    const [loadingTable,setLoadingTable] = useState(false);
    const [form] = Form.useForm();
    const isEditing = (record) => record.bankName === editingKey;

    const cancel = () => {
        setEditingKey('');
    };

    const save =  async (bankName) => {
        setLoadingTable(true);
        try {
            const row = await form.validateFields();
            const params = {
                bankName,
                ...row
            };
            const response = await updateBank(accessToken,params);
            if (response.success) {
                const newData = [...dataSource];
                const index = newData.findIndex((item) => bankName === item.bankName);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                    setDataSource(newData);
                    setEditingKey('');
                } else {
                    newData.push(row);
                    setDataSource(newData);
                    setEditingKey('');
                }
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
            setLoadingTable(false);
        }
    }

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'bankName',
            width: '65px',
            render: (value,record) => {
                return (
                    <Flex
                        justify={'center'}
                        align={'center'}
                    >
                        <Tag>{value}</Tag>
                    </Flex>
                )
            }
        },
        {
            title: 'Icon',
            dataIndex: 'icon',
            width: '60px',
            render: (value) => {
                return (
                    <Flex
                        justify={'center'}
                        align={'center'}
                    >
                        <Image
                            src={value}
                            width={40}
                            height={40}
                        />
                    </Flex>
                )
            }
        },
        {
            title: 'Tên gọi',
            dataIndex: 'displayName',
            inputType: 'text',
            editable: true,
        },
        {
            title: 'Loại',
            dataIndex: 'type',
            align: 'center',
            render: (value) => {
                if (value === 'wallet') {
                    return <Tag icon={<WalletOutlined/>} color={'#ee5583'}>Ví điện tử</Tag>
                }else if (value === 'bank') {
                    return <Tag icon={<BankOutlined/>} color={'#55acee'}>Ngân hàng</Tag>
                }else if (value === 'business') {
                    return <Tag icon={<BankOutlined/>} color={'#ee7155'}>Doanh nghiệp</Tag>
                }
            }
        },
        {
            title: 'Time out (giây)',
            dataIndex: 'timeOut',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối thiểu nhận (VNĐ)',
            dataIndex: 'minAmountPayInLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối đa nhận (VNĐ)',
            dataIndex: 'maxAmountPayInLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối thiểu chuyển (VNĐ)',
            dataIndex: 'minAmountPayOutLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối đa chuyển (VNĐ)',
            dataIndex: 'maxAmountPayOutLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối đa nhận (lần/ngày)',
            dataIndex: 'dailyReceiveLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tối đa chuyển (lần/ngày)',
            dataIndex: 'dailyTransferLimit',
            inputType: 'number',
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Hạn mức ngày nhận (VNĐ)',
            dataIndex: 'maxAmountLimitPerDay',
            inputType: 'number',
            sorter: true,
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Hạn mức chuyển ngày (VNĐ)',
            dataIndex: 'maxAmountLimitTransferPerDay',
            inputType: 'number',
            sorter: true,
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Hạn mức nhận tháng (VNĐ)',
            dataIndex: 'maxAmountLimitPerMonth',
            inputType: 'number',
            sorter: true,
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Hạn mức chuyển tháng (VNĐ)',
            dataIndex: 'maxAmountLimitTransferPerMonth',
            inputType: 'number',
            sorter: true,
            editable: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Tự động bật tắt',
            dataIndex: 'isAutoControl',
            inputType: 'switch',
            editable: true,
            render: (value) => {
                return value === true ? <Tag color={'success'}>Mở</Tag> : <Tag color={'red'}>Đóng</Tag>;
            }
        },
        {
            title: 'Giờ tắt',
            dataIndex: 'stopTime',
            inputType: 'text',
            width: '100px',
            editable: true,
            render: (value) => {
                if (value) {
                    return <Tag>{value}</Tag>
                }
            }
        },
        {
            title: 'Giờ bật',
            dataIndex: 'startTime',
            inputType: 'text',
            width: '100px',
            editable: true,
            render: (value) => {
                if (value) {
                    return <Tag>{value}</Tag>
                }
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'isEnabled',
            inputType: 'switch',
            editable: true,
            render: (value) => {
                return value === true ? <Tag color={'success'}>Mở</Tag> : <Tag color={'red'}>Đóng</Tag>;
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            render: (_,record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button
                            type={'primary'}
                            onClick={() => save(record.bankName)}
                            ghost={true}
                        >Lưu</Button>
                        <Button
                            type={'dashed'}
                            onClick={cancel}
                            danger={true}
                        >Hủy</Button>
                    </Space>
                ) : (
                    <Button icon={<EditOutlined/>} disabled={editingKey !== ''} onClick={() => edit(record)} type={'primary'}>Chỉnh sửa</Button>
                );
            }
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoadingTable(true);
            const response = await getBanks(accessToken);
            if (response.success) {
                setDataSource(response.data);
            }
            setLoadingTable(false);
        }
        fetchData();
    }, []);


    const edit = (record) => {
        form.setFieldsValue({
            dailyReceiveLimit: '',
            dailyTransferLimit: '',
            maxAmountLimitPerDay: '',
            maxAmountLimitPerMonth: '',
            maxAmountPayInLimit: '',
            maxAmountPayOutLimit: '',
            minAmountPayInLimit: '',
            minAmountPayOutLimit: '',
            timeOut: '',
            ...record,
        });
        setEditingKey(record.bankName);
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <Container>
                <Card
                    title={'Cấu hình ngân hàng'}
                >
                    <Space
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        <Button type={'primary'} ghost={true} onClick={() => {}} icon={<FontAwesomeIcon icon={faAdd}/>}>Thêm mới</Button>
                    </Space>
                    <Form
                        form={form}
                        component={false}
                    >
                        <Table
                            rowKey={'bankName'}
                            columns={mergedColumns}
                            scroll={{
                                x: 2200,
                            }}
                            bordered={true}
                            dataSource={dataSource}
                            loading={loadingTable}
                            pagination={{
                                pageSize: 50
                            }}
                            components={{
                                body: {
                                    cell: EditTableCell
                                }
                            }}
                        />
                    </Form>
                </Card>
            </Container>
        </>
    )
}

export default Bank;