import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import LoadingProvider from './providers/LoadingProvider';
import { Provider } from "react-redux";
import store from './stores/store'
import {ConfigProvider} from "antd";
import './index.css'
import AuthProvider from "react-auth-kit";

import authStore from "./stores/authStore";
import viVN from 'antd/locale/vi_VN'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider locale={viVN}>
        <LoadingProvider>
            <AuthProvider store={authStore}>
                <Provider store={store}>
                    <App />
                </Provider>
            </AuthProvider>
        </LoadingProvider>
    </ConfigProvider>
);
