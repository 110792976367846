import {createSlice} from "@reduxjs/toolkit";

export const CollapsedSlice = createSlice({
    name: "collapsed",
    initialState: {
        state: (localStorage.getItem("collapsed") === "true")
    },
    reducers: {
        toggleCollapsed: (state) => {
            state.state = !state.state;
            localStorage.setItem("collapsed", state.state === true ? "true" : "false");
        }
    }
});

export const {toggleCollapsed} = CollapsedSlice.actions;

export default CollapsedSlice.reducer;