import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        accessToken: localStorage.getItem("accessToken"),
        userData: JSON.parse(localStorage.getItem("userData") ?? '{}'),
        expiredAt: Number(localStorage.getItem("expiredAt")),
        isLogout: !localStorage.getItem("accessToken"),
    },
    reducers: {
        userUpdate: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.userData = action.payload.userData;
            state.expiredAt = action.payload.expiredAt;
            state.isLogout = false;
        },
        userLogout: (state) => {
            state.accessToken = null;
            state.userData = {};
            state.expiredAt = 0;
            state.isLogout = true;
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("expiredAt")
        },
    },
});

export const { userUpdate, userLogout } = UserSlice.actions;
export default UserSlice.reducer;
