import classnames from 'classnames/bind'
import styles from './Button.module.scss'

const cx = classnames.bind(styles);

function Button(props) {
    return (
        <div className={cx('form-control')}>
            <button className={cx('btn')} {...props}>{props.children}</button>
        </div>
    );
}

export default Button;