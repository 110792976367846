import axios from "../axios";

const getGoogleSecretKey = async function (accessToken) {
    try {
        const response = await axios.get('/user/getGoogleSecretKey', {
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getGoogleSecretKey;