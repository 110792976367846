import axios from "../../axios";

const getPayIns = async function (accessToken,params = {}) {
    try {
        const response = await axios.post('admin/pay-in/getPayIns', params,{
            headers: {
                'Authorization': accessToken
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getPayIns;