import axios from "../../axios";

const addPartner = async function (accessToken, data = {}) {
    try {
        const response = await axios.post('/bank-account/bank-account-private/addPartner', data,{
            headers: {
                'Authorization': accessToken
            },
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default addPartner;