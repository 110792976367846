import axios from "../../axios";

const getLogs = async function (accessToken,payOutId = {}) {
    try {
        const response = await axios.get('admin/log-pay-out/getLogs' ,{
            headers: {
                'Authorization': accessToken
            },
            params: {
                pay_out_id: payOutId
            }
        });
        return response.data;
    }
    catch (e) {
        return {
            success: false,
            errorCode: e.response.status
        }
    }
}

export default getLogs;