import classnames from 'classnames/bind';
import styles from './CheckBox.module.scss';

const cx = classnames.bind(styles);

function CheckBox(props) {
    return (
        <div className={cx('wrapper')}>
            <input type="checkbox"id={"remember"} {...props}/>
            <label htmlFor={"remember"}>Nhớ tài khoản</label>
        </div>
    );
}

export default CheckBox;