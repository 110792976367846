import Auth from "../../components/Auth/Auth";
import Error from "../../components/Auth/Error";
import Form from "../../components/Auth/Form";
import Input from "../../components/Auth/Form/Input";
import Button from "../../components/Auth/Form/Button";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import verify from "../../api/auth/verify";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import LoadingContext from "../../contexts/LoadingContext";
import {loginError, loginSuccess} from "../../redux/AuthSlice";
import {userUpdate} from "../../redux/UserSlice";
import {useDispatch} from "react-redux";

function Verify() {
    const [otp,setOtp] = useState();
    const [isError,setIsError]  = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const navigate = useNavigate();
    const deviceId = localStorage.getItem('deviceId');
    const signIn = useSignIn();
    const dispatch = useDispatch();
    const {setLoading} = useContext(LoadingContext)
    const handleSubmit = async () => {
        setLoading(true);
        const response = await verify(deviceId,otp);
        if (!response.success) {
            dispatch(loginError({
                message: response.message
            }));
            setIsError(true);
            setErrorMessage(response.message);
        }
        else {
            const data = response.data;
            dispatch(loginSuccess(data));
            dispatch(userUpdate(data));
            signIn({
                auth: {
                    token: response.data.accessToken,
                    type: "Bearer"
                },
                refresh: response.data.accessToken,
                userState: response.data.userData
            });
            navigate("/");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!deviceId || deviceId.length <= 0) {
            navigate('/auth/login');
        }
    }, []);
    return (
        <Auth title="Xác minh bảo mật Google Authenticate" error={isError && <Error>{errorMessage}</Error>}>
            <Form>
                <Input label={"Nhập mã xác minh"} type={"text"} onChange={(e) => setOtp(e.target.value)} />
                <Button onClick={handleSubmit}>Xác nhận</Button>
            </Form>
        </Auth>
    );
}

export default Verify;